import * as React from 'react';

const Navbar = () => {
    return (
        <div className="bg-red-300 py-4 ">
            <div className={"flex flex-row items-center"}>
                <div className={''}>
                    <img src={require("../sziv_logo.png")} alt="" width="260" height="260"/>
                </div>
                <div>
                    <div className="text-white text-5xl">Szív-Kör Fesztivál</div>
                    <div className="text-white text-3xl">Regisztrációs felület</div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;

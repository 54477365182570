import Navbar from "./components/Navbar";
import RegistrationFormHeader from "./components/RegistrationFormHeader";
import RegistrationForm from "./components/RegistrationForm";

function App() {
  return (
      <div className="scroll-smooth">
          <Navbar />
          <div className="max-w-7xl mx-auto px-4">
              <div className="pt-10 text-3xl text-left sm:text-justify prose prose-2xl">
                  <p className={'font-bold text-4xl'}>Kedves Szív-Kör támogatók!</p>
                  <p>A SZÍV-KÖR Egyesületet (<a href={"https://sziv-kor.hu/"} className={"text-blue-600  break-words"}>https://sziv-kor.hu/</a>, <a href={"https://www.facebook.com/groups/sziv.kor.egyesulet"} className={"text-blue-600  break-all"}>https://www.facebook.com/groups/sziv.kor.egyesulet</a>) szívbeteg gyermeket nevelő szülők azzal a céllal hozták létre 2020-ban, hogy hivatalos szervezetként összefogja és képviselje a szívbetegséggel élő gyermekeket és családjaikat. Az Egyesület megalakulása óta tervezi egy családi nap hagyományának megteremtését, melynek első rendezvénye Dabason, a Halász Móricz-kúriában, 2022. szeptember 24-én kerül megrendezésre.  A családi nap a SZÍV-KÖR FESZTIVÁL elnevezést kapta, amit egy kötetlen, közösségteremtő, vidám hangulatú programként álmodtunk meg.
                      A rendezvényt támogatja Dabas Város Önkormányzata, a Szívbeteg Gyermekek Védelmében Alapítvány, a Nemzeti Kulturális Alap, valamint számos gazdasági társaság és magánszemély.</p>
              </div>
              <div>
                  <div className="text-center pt-16 text-3xl prose font-bold">TERVEZETT PROGRAMOK</div>
                  <div className="grid grid-rows-8 sm:grid-rows-4 md:grid-rows-4 grid-flow-col gap-4 text-center pt-14 text-2xl">
                      <div className="">
                          <div>
                              BÁBELŐADÁS
                          </div>
                          <div className={'hidden'}>
                              KÉP
                          </div>
                      </div>
                      <div>
                          <div>
                              ZENÉS PRODUKCIÓ
                          </div>
                          <div className={'hidden'}>
                              KÉP
                          </div>
                      </div>
                      <div>
                          <div>
                              BŰVÉSZ BEMUTATÓ
                          </div>
                          <div className={'hidden'}>
                              KÉP
                          </div>
                      </div>
                      <div>
                          <div>
                              TOMBOLA
                          </div>
                          <div className={'hidden'}>
                              KÉP
                          </div>
                      </div>
                      <div>
                          <div>
                              KÉZMŰVES PROGRAMOK
                          </div>
                          <div className={'hidden'}>
                              KÉP
                          </div>
                      </div>
                      <div>
                          <div>
                              ÁLLATSIMOGATÓ
                          </div>
                          <div className={'hidden'}>
                              KÉP
                          </div>
                      </div>
                      <div>
                          <div>
                              BÜFÉ
                          </div>
                          <div className={'hidden'}>
                              KÉP
                          </div>
                      </div>
                  </div>
              </div>
              <div className="text-3xl pt-14 prose text-left sm:text-justify">
                  A fesztiválhelyszín adottságait figyelembe véve előreláthatóan 250 vendéggel tudunk számolni, emiatt a részvételhez regisztráció szükséges. Amennyiben a SZÍV-KÖR FESZTIVÁL felkeltette érdeklődését és szeretne családjával ellátogatni a rendezvényre, kérjük töltse ki az alábbi regisztrációs kérdőívet. A megadott adatokat a szervezők elsősorban a kapcsolattartás céljából kérik és a lenti adatvédelmi szabályzatnak megfelelően fogják kezelni.
              </div>
          </div>
          <RegistrationFormHeader />
          <div className="max-w-3xl mx-auto">
              <RegistrationForm />
          </div>
      </div>

  );
}

export default App;

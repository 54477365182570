import * as React from 'react';
import {useState} from "react";
import {addDoc, collection, doc, getDoc, updateDoc} from 'firebase/firestore';
import {getFirestore as db, auth, signInAnonymously} from '../firebase_config';
import Modal from "./Modal";


const RegistrationForm = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [willParticipate, setWillparticipate] = useState("Igen");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [numberOfParticipants, setNumberOfParticipants] = useState(1);
    const [childName, setChildName] = useState("");
    const [childAge, setChildAge] = useState(1);
    const [bait, setBait] = useState("");
    const [agreement, setAgreement] = useState(false);
    const [registrationResult, setRegistrationResult] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [willUseBus, setWillUseBus] = useState("Nem");
    const [busUserNumber, setBusUserNumber] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        //console.log('submitting');
        if(bait !== ""){
            return;
        }
        if(agreement !== true){
            prompt("Az adatkezelési nyilatkozat elfogadása kötelező");
            return;
        }
        try {
            let user;
            if (auth.currentUser === null) {
                user = await signInAnonymously(auth);
            }

            if (user !== null) {
                //console.log(user);
                const counterRef = doc(db(), "counter", "the_counter");
                const counter = await getDoc(counterRef);
                const participants_data = counter.data().participants;
                const participants = +participants_data + +numberOfParticipants

                if(participants <= 250) {
                    const collectionRef = collection(db(), "registered_users");
                    const payload = {
                        name: name,
                        email: email,
                        address: address,
                        phoneNumber: phoneNumber,
                        willParticipate: willParticipate,
                        numberOfParticipants: +numberOfParticipants,
                        childName: childName,
                        childAge: childAge,
                        willUseBus: willUseBus,
                        busUsers: +busUserNumber,
                        registeredAt: new Date().toLocaleString('hu')
                    };
                    let result = await addDoc(collectionRef, payload);
                    if (result !== null && result.path.includes('registered_users')) {
                        //console.log('setting reg result to true');
                        setRegistrationResult(true);

                        await updateDoc(counterRef, {
                            participants: participants
                        });

                        const busCounterRef = doc(db(), "counter", "bus_counter");
                        const busCounter = await getDoc(busCounterRef);
                        const bus_participants_data = busCounter.data().busUsers;
                        const busUsers = +bus_participants_data + +busUserNumber

                        await updateDoc(busCounterRef, {
                            busUsers: busUsers
                        });
                        //console.log(counter.data());
                    } else {
                        //console.log('setting reg result to false');
                        setRegistrationResult(false);
                    }
                }
                else {
                    setErrorMessage("Az esemény megtelt.");
                }
                //console.log(result);

            } else {
                //console.log('setting reg result to false');
                setRegistrationResult(false);
            }
        }
        catch (e) {
            //console.log(e);
            if(e.message.includes("Missing or insufficient permissions")){
                setErrorMessage("Az esemény megtelt.");
            }
            else {
                setErrorMessage(e);
            }
            setRegistrationResult(false);
        }

        //console.log('setting showmodal  to true');
        setLoading(false);
        setShowModal(true);
    }

    const resetState = () => {
        setName("");
        setEmail("");
        setPhoneNumber("");
        setAddress("");
        setNumberOfParticipants(1);
        setBusUserNumber(0);
        setChildAge(1);
        setChildName("");
        setWillparticipate("Igen");
        setAgreement(false);
        setBait("");
    }

    const handleParticipantNumberChange = (event) => {
        setNumberOfParticipants(event.target.value);
    };

    const handleBusUserNumberChange = (event) => {
        setBusUserNumber(event.target.value);
    };

    const handleWillUseBusChange = (event) => {
        setWillUseBus(event.target.value);
    };

    const handleChildAgeChange = (event) => {
        setChildAge(event.target.value);
    };

    const handleBaitChange = (event) => {
        setBait(event.target.value);
    };

    const handleChildNameChange = (event) => {
        setChildName(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleAgreementChange = (event) => {
        setAgreement(event.target.checked);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    }

    const handleParticipationChange = (event) => {
        //console.log(event.target.value);
        setWillparticipate(event.target.value);
    }



    return (
        <form className="mb-12 mt-12 px-4" onSubmit={handleSubmit}>
            <div className="mb-6">
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-black">*Email cím</label>
                <input type="email" id="email" value={email} onChange={handleEmailChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                       required={true}/>
            </div>
            <div className="mb-6">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-black">*Név</label>
                <input type="text" id="name" value={name} onChange={handleNameChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                       required={true}/>
            </div>

            <div className="mb-6">
                <label htmlFor="participation" className="block mb-2 text-sm font-medium text-black">*A Szív-Kör Fesztiválon (Halász Móricz-kúria, Dabas, 2022. szeptember 24.) szeretnék résztvenni</label>
                <select id="participation" onChange={handleParticipationChange} value={willParticipate}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                    <option>Igen</option>
                    <option>Nem</option>
                </select>
            </div>


            <div className="mb-6">
                <label htmlFor="address" className="block mb-2 text-sm font-medium text-black">*Cím</label>
                <input type="text" id="address" value={address} onChange={handleAddressChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                       required={true}/>
            </div>

            <div className="mb-6">
                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-black">Telefonszám</label>
                <input type="tel" id="phone" value={phoneNumber} onChange={handlePhoneNumberChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
            </div>

            <div className="mb-6">
                <label htmlFor="participants" className="block mb-2 text-sm font-medium text-black">*A Szív-Kör Fesztiválon előreláthatóan a családunkból résztvevők száma (tekintettel arra, hogy a rendezvény összlétszámát a helyszín adottságai alapján 250 főben határoztuk meg, annak érdekében, hogy minél több családból vehessenek részt a rendezvényen, a közvetlen családtagok érkezésére számítunk).</label>
                <input type="number" id="participants" value={numberOfParticipants} onChange={handleParticipantNumberChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                       required={true}/>
            </div>

            <div className="mb-6">
                <label htmlFor="will_use_bus" className="block mb-2 text-sm font-medium text-black">Amennyiben a rendezvény helyszínére ingyenes autóbusz különjáratot indítanak a szervezők, azt igénybe szeretném venni</label>
                <select id="will_use_bus" onChange={handleWillUseBusChange} value={willUseBus}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                    <option>Nem</option>
                    <option>Igen</option>
                </select>
            </div>

            {willUseBus === 'Igen' ? (
                <div className="mb-6">
                    <label htmlFor="bus_users" className="block mb-2 text-sm font-medium text-black">Az autóbusz különjáratot hány fővel szeretném igénybe venni</label>
                    <input type="number" id="bus_users" value={busUserNumber} onChange={handleBusUserNumberChange}
                           className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           required={true}/>
                </div>
            ) : null}


            <div className="mb-6">
                <label htmlFor="child_name" className="block mb-2 text-sm font-medium text-black">*Szívbeteg gyermekem neve</label>
                <input type="text" id="child_name" value={childName} onChange={handleChildNameChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                       required={true}/>
            </div>

            <div className="mb-6">
                <label htmlFor="child_age" className="block mb-2 text-sm font-medium text-black">*Szívbeteg gyermekem életkora</label>
                <input type="number" id="child_age" value={childAge} onChange={handleChildAgeChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                       required={true}/>
            </div>

            <div className="hidden">
                <label htmlFor="bait" className="block mb-2 text-sm font-medium text-black"></label>
                <input type="text" id="bait" value={bait} onChange={handleBaitChange}
                       className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                       />
            </div>

            <div className={'text-left sm:text-justify'}>
                Adatkezelési nyilatkozat Szív-Kör Fesztivál – Dabas, 2022. szeptember 24.
                <br/><br/> Hozzájárulok, mint a fent nevezett kiskorú személy törvényes képviselője, hogy a Szív-Kör Egyesület - továbbiakban mint Adatkezelő - a fent nevezett rendezvényre szóló meghívó küldéséhez, valamint az ahhoz szükséges adatbázis összeállításához kapcsolódó adatkezelés érdekében a személyes adataimat/a fent nevezett kiskorú személyes adatait kezelje.
                <br/><br/>Kijelentem, hogy az Adatkezelő tájékoztatását, mely szerint a fentiekben adott hozzájárulásom bármikor korlátozás nélkül az Adatkezelőhöz címzett értesítéssel a zárójelben feltüntetett elérhetőségek valamelyikén visszavonhatom (Szív-Kör Egyesület, 8000 Székesfehérvár, Budai út 145/B., szivkoregyesulet@gmail.com), valamint, hogy a hozzájárulás visszavonása nem érinti a visszavonás előtti, – a hozzájárulás alapján végrehajtott – adatkezelés jogszerűségét tudomásul vettem.
                <br/><br/>Hozzájárulok/mint a fent nevezett kiskorú személy törvényes képviselője kifejezetten hozzájárulok, hogy az Adatkezelő hírlevél-szolgáltatáshoz, valamint az ahhoz szükséges adatbázis összeállításához kapcsolódó adatkezelés érdekében személyes és egészségügyi adataim/a fent nevezett kiskorú személyes és egészségügyi adatait az Adatkezelési Tájékoztatóban foglaltak szerint kezelje.
                <br/><br/>Tudomásul veszem, hogy Adatkezelő személyes adataim a hírlevél küldés kapcsán a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény a reklám közvetlen megkeresés módszerével történő megküldésére vonatkozó rendelkezései - 6. §. (1) bekezdése - alapján kezeli.
                <br/><br/>Kijelentem, hogy az Adatkezelő tájékoztatását, mely szerint a fentiekben adott hozzájárulásom bármikor korlátozás nélkül az Adatkezelőhöz címzett értesítéssel a zárójelben feltüntetett elérhetőségek valamelyikén visszavonhatom (Szív-Kör Egyesület, 8000 Székesfehérvár, Budai út 145/B., szivkoregyesulet@gmail.com), valamint, hogy a hozzájárulás visszavonása nem érinti a visszavonás előtti, – a hozzájárulás alapján végrehajtott – adatkezelés jogszerűségét tudomásul vettem.
                <br/><br/>Kifejezetten hozzájárulok/mint a fent nevezett kiskorú személy törvényes képviselője kifejezetten hozzájárulok, hogy az Adatkezelő adományozói nyilvántartáshoz, valamint az ahhoz szükséges adatbázis összeállításához kapcsolódó adatkezelés érdekében személyes és egészségügyi adataim/a fent nevezett kiskorú személyes és egészségügyi adatait az Adatkezelési Tájékoztatóban foglaltak szerint kezelje.
                <br/><br/>Hozzájárulok, hogy a fent nevezett kiskorúról/rólam készült fotót, személyes adatokat és szöveges információkat a Szív-Kör Egyesület marketingkommunikációs anyagaiban, Facebook oldalán https://www.facebook.com/groups/sziv.kor.egyesulet és weboldalán https://sziv-kor.hu/ bemutatása céljából kezelhesse.
                <br/><br/>Kijelentem továbbá, hogy a fentiekben meghatározott fotók, szövegek megjelentetéséhez, sokszorosításához, terjesztéséhez, nyilvánosságra hozatalához való hozzájárulásomat ingyenesen, térítésmentesen teszem.
            </div>

            <div className="mt-6">

                <fieldset>
                    <div className="flex items-center mb-4">
                        <input checked={agreement} id="checkbox-1" type="checkbox" onChange={handleAgreementChange}
                               className="w-20 h-20 sm:w-10 sm:h-10 text-blue-600 bg-gray-100 border-gray-300 rounded" required={true}/>
                            <label htmlFor="checkbox-1"
                                   className="ml-2 text-black text-left sm:text-justify" >Fenti kiskorú személy törvényes képviselője kijelentem, hogy fenti adatkezelési nyilatkozat megismerésével és elfogadásával hozzájárulok ahhoz, hogy a megadott adatokat a Szív-Kör Egyesület, mint Adatkezelő kezelhesse.</label>
                    </div>
                </fieldset>

            </div>

            <div className="text-center pt-4">
                {loading ? <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Feldolgozás...
                    </button> : <button type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Jelentkezés
                    </button>}

            </div>
            {showModal ? <Modal success={registrationResult} resetState={resetState} setShowModal={setShowModal} errorMessage={errorMessage}/> : null }
        </form>
);
};

export default RegistrationForm;

import * as React from 'react';

const Modal = ({success, resetState, errorMessage,setShowModal}) => {
    const onClick = () => {
        if(success){
            resetState();
            setShowModal(false);
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            }
            window.location.reload();
        }
        else {
            setShowModal(false);
        }
    }
    return (
        <div id="popup-modal" tabIndex="-1"
             className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 inset-0 md:inset-0 h-modal h-full md:h-full bg-gray-400/70">
            <div className="absolute inset-0 flex items-center justify-center p-4 w-full max-w-md h-full m-auto">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="p-6 text-center">
                        {success ? <svg className="mx-auto mb-4 w-14 h-14 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg> :
                            <svg className="mx-auto mb-4 w-14 h-14 text-red-400" fill="none"
                            stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                        }
                        <h3 className="mb-5 text-lg font-normal text-gray-500 ">{success ? "Sikeres regisztráció" : `Sikertelen regisztráció. ${errorMessage}`}</h3>
                        {success ? <button onClick={onClick} data-modal-toggle="popup-modal" type="button"
                                           className="text-white bg-green-600 hover:bg-green-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Rendben
                        </button> : <button onClick={onClick} data-modal-toggle="popup-modal" type="button"
                                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Rendben
                        </button>}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCtpjruY4hPiK1KUhDTpRu3Gsns6gUDjwA",
    authDomain: "sziv-kor-regisztracio.firebaseapp.com",
    projectId: "sziv-kor-regisztracio",
    storageBucket: "sziv-kor-regisztracio.appspot.com",
    messagingSenderId: "849105066377",
    appId: "1:849105066377:web:9567f6ed92556701636958"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export {getFirestore, auth, signInAnonymously};

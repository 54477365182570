import * as React from 'react';

const RegistrationFormHeader = () => {
    return (
        <div className="pt-16"><div className="grid grid-rows-2 text-center text-4xl bg-[#b0d9b8]" id="register">
            <div className="pt-8">
                REGISZTRÁLOK
            </div>
            <div className="pb-8">
                -SZERETNÉK RÉSZT VENNI A FESZTIVÁLON-
            </div>
        </div>
        </div>

    );
};

export default RegistrationFormHeader;
